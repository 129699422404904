import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import { HiMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import "./Navbar.css";
import logo from "../../../assets/images/logo.png";
import { BsWhatsapp } from "react-icons/bs";
import SearchBar from "../../SearchBar";
import SearchResultsList from "../../SearchResultsList";

const WpHeader = () => {
  const [stickyClass, setStickyClass] = useState("");
  const [filterState, setFilterState] = useState(false);
  console.log(filterState);
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      if (windowHeight > 100) {
        document.getElementById("navbar").classList.add("sticky");
        setStickyClass("fixed");
      } else {
        setStickyClass("");
      }
    }
  };
  const [toggleMenu, setToggleMenu] = useState(false);
  const [results, setResults] = useState([]);
  return (
    <header
      className={`text-gray-400 bg-white drop-shadow-2xl w-full body-font ${stickyClass} z-50`}
      id="navbar"
    >
      <div className="mx-auto flex items-center relative z-50">
        <Link
          to="/"
          className="flex title-font font-medium items-center text-gray-900 xl:ml-24 lg:ml-24 md:ml-4 "
        >
          <img className="w-[50%] m-2" src={logo} alt="logo" />
        </Link>
        <div className=" md:ml-auto hidde xl:flex lg:flex md:flex sm:hidde w-[90%] xl:w-[90%] lg:w-[90%] md:w-[94%] justify-center items-center">
          <nav className="flex flex-wrap items-center text-base justify-center">
            <SearchBar setResults={setResults} />
            {results && results.length > 0 && (
              <SearchResultsList results={results} />
            )}
          </nav>
        </div>
        <div className="flex relative">
          {toggleMenu ? (
              <AiOutlineClose
                  fontSize={0}
                  className="text-black md:hidden cursor-pointer"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
              />
          ) : (
              <HiMenuAlt4
                  fontSize={35}
                  className="text-black md:hidden cursor-pointer"
                  onClick={() => {
                    setToggleMenu(true);
                  }}
              />
          )}
          {toggleMenu && (
              <ul className="z-10 fixed top-0 right-0 p-3 w-[100vw] h-screen shadow-2xl md:hidden list-none flex flex-col justify-start items-end blue-glassmorphism text-black items-center text-3xl animate-slide-in">
                <li className="text-xl w-full my-2">
                  <AiOutlineClose
                      fontSize={35}
                      className="text-black md:hidden cursor-pointer"
                      onClick={() => {
                        setToggleMenu(false);
                      }}
                  />
                </li>
                <li className="my-6 mt-12">
                  <a
                      href="/"
                      className="mr-4 font-semibold cursor-pointer p-2"
                      id="btn"
                  >
                    Home
                  </a>
                </li>
                <li className="my-6">
                  <a
                      href="https://panda.toolsfix.net/member"
                      className=" mr-2 font-semibold cursor-pointer p-2"
                      id="btn"
                  >
                    Member Area
                  </a>
                </li>
                <li className="my-6">
                  <a
                      href="https://panda.toolsfix.net/signup"
                      className="mr-4 font-semibold cursor-pointer p-2"
                      id="btn"
                  >
                    Buy Subscriptions
                  </a>
                </li>
                <li className="my-6">
                  <Link
                      to="/youtubeMonetization"
                      className=" mr-2 font-semibold cursor-pointer p-2"
                      id="btn"
                  >
                    Youtube Monetization
                  </Link>
                </li>
                {/*<li className="my-6">*/}
                {/*  <Link*/}
                {/*    to="/wp"*/}
                {/*    className=" mr-2 font-semibold cursor-pointer p-2"*/}
                {/*    id="btn"*/}
                {/*  >*/}
                {/*    WordPress Plugins*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*<li className='my-6'>*/}
                {/*    <a target="_blank" href="https://wa.me/923218043010"*/}
                {/*       className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-bold rounded-full text-medium px-8 py-4 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"*/}
                {/*       rel="noreferrer"*/}
                {/*    >*/}
                {/*        <BsWhatsapp className="inline" size={20}/>&nbsp;&nbsp; Chat on WhatsApp*/}
                {/*    </a>*/}
                {/*</li>*/}
                <li className="my-6 mt-12">
                  <a
                      href="https://panda.toolsfix.net/signup"
                      className="text-[#FFFEFA] bg-[#454545] hover:text-white focus:outline-none focus:ring-4 hover:bg-stone-500 font-bold rounded-full text-medium px-8 py-4 text-center mr-2 mb-2"
                      rel="noreferrer"
                  >
                    Signup Today{" "}
                    <span>
                    <HiChevronDoubleRight className="inline" />
                  </span>
                  </a>
                </li>
              </ul>
          )}
        </div>
        <a
          href="/"
          className="hidden xl:flex lg:flex md:flex sm:hidden justify-center items-center my-auto w-[24%] text-[#FFFEFA] bg-[#454545] hover:text-white hover:bg-stone-500 focus:outline-none focus:ring-4 focus:ring-[#FFFEFA] font-bold rounded-full text-medium px-6 py-3 text-center mr-2 "
          rel="noreferrer"
        >
          Home{" "}
          <span>
            <HiChevronDoubleLeft className="inline" />
          </span>
        </a>
      </div>
    </header>
  );
};

export default WpHeader;
