import React from 'react';
import {BsWhatsapp} from "react-icons/bs";

const YTHero = () => {
    return (
        <section>
            <div className="text-center">
                <h1 className="title-font text-2xl sm:text-2xl xl:text-5xl lg:text-5xl md:text-3xl mb-4 font-black text-black">
                    Boost Your <span className="text-[#454545]">YouTube Channel's Monetization</span> - Genuine
                    Subscribers, Views, and Watch Time </h1>
                <hr className="w-48 h-1 mx-auto my-4 bg-[#454545] border-0 rounded md:my-10 dark:bg-gray-700"/>
                <p className="my-8 leading-relaxed text-gray-500 text-lg font-bold">Prepare for YouTube
                    Monetization Service with 50% Off Limited Time Offer</p>
                {/*<div className="flex justify-center items-center my-24">*/}
                {/*    <iframe width="560" height="315" src="https://www.youtube.com/watch?v=https://www.youtube.com/embed/cNyJe-EzXEc"*/}
                {/*            title="YouTube video player"*/}
                {/*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                {/*            allowFullScreen></iframe>*/}
                {/*</div>*/}
                {/*<a target="_blank" href="https://wa.me/923218043010"*/}
                {/*   className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-bold rounded-full text-medium px-8 py-4 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"*/}
                {/*   rel="noreferrer"*/}
                {/*>*/}
                {/*    <BsWhatsapp className="inline" size={22}/>&nbsp;&nbsp; Chat on WhatsApp*/}
                {/*</a>*/}
            </div>
        </section>
    );
};

export default YTHero;
