import React from 'react'

const Demo = () => {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-0 sm:px-0 md:px-5 pb-24 mx-auto w-full xl:w-[90%] lg:w-[90%] md:w-[90%] sm:w-full text-center">
                <div className="flex flex-col text-center w-full mb-8">
                    <h1 className="text-4xl xl:text-6xl lg:text-6xl md:text-6xl sm:text-4xl font-bold title-font text-gray-900 mb-4">Get Yours 3 Days Free Demo</h1>
                </div>
                <p className='mb-8 leading-relaxed text-gray-500 text-lg w-full sm:w-full md:w-1/2 mx-auto'>
                    Unlock the Secret of Group Buy Tools ! 🔒 Dive into a 3-day demo to test-drive our system and seamlessly explore the ease of access to premium tools. Free Sign up now to experience efficiency at your fingertips – your digital journey starts here!
                </p>
                <a href="https://panda.toolsfix.net/signup/demo"
                    className='mx-auto text-white hover:text-[#454545] bg-[#454545] hover:bg-white  font-bold rounded-full py-3 px-8 w-full text-center text-lg white:text-white hover:shadow-custom hover:border-[#454545] border-4'
                >Get Demo!</a>
            </div>
        </section>
    )
}

export default Demo