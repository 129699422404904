import React from 'react'

const Payment = () => {
    return (
        <section className="text-gray-600 body-font">
            <div className='container px-0 sm:px-0 md:px-5 pt-24 mx-auto'>
                <div className="text-center mb-20">
                    <h1 className="text-4xl xl:text-6xl lg:text-6xl md:text-6xl sm:text-4xl font-bold title-font text-gray-900 mb-4">Offline Payment</h1>
                </div>

                <p>Share your username or mail id along with the payment screenshot. Drop Your Payment Screenshot here:</p>

                <p className='py-4'>
                    <strong>Click On it &gt; &gt;</strong>&nbsp;<a href="https://wa.link/egz0yw" target="_blank" className='text-red-600 underline'><span><strong>What&#39;s App Support</strong></span></a>
                    <br />
                    <span ><strong>What&#39;s App Number:</strong></span><span > <strong className='text-red-600'>0321 8043010</strong></span>
                </p>

                <p>Don&#39;t Call on what&#39;s App&nbsp;Just drop the screenshot of payment, team will assist you within some time (maximum 20 min). So, Please Stay Calm : )&nbsp;</p>
                <div className='w-full flex flex-wrap justify-evenly items-start'>
                    <div className='md:w-1/2  flex flex-col items-center border-2 border-gray-600 p-7 mt-6 md:pb-[97px] lg:pb-[225px] 2xl:pb-[330px] md:border-r-0'>
                        <h3 className='text-xl py-4 font-semibold'>Pakistani Payment Method (Offline)</h3>
                        <p ><strong>Meezan Bank Account:</strong></p>

                        <p className='py-4'><strong>Bank Accunt Title:</strong> <em>KAMRAN KHAN</em><br />
                            <strong>Bank Account Number:</strong><em> 02630107561345</em> <br />
                            <strong>Meezan IBAN ( International Payment ):</strong><em> PK57MEZN0002630107561345</em>
                        </p>

                        <p >If you do not have an account with <strong>Meezan Bank</strong>, there is no need to worry. <strong><span className='text-red-600'>You have the option to transfer funds from any of your existing bank accounts to our Meezan Bank</span></strong>. The process is straightforward, similar to transferring funds between <strong>EasyPaisa</strong> and <strong>JazzCash</strong>, or vice versa.</p>
                    </div>
                    <div className='md:w-1/2 flex flex-col items-center border-2 border-gray-600 p-7 my-6'>
                        <h3 className='text-xl py-4 font-semibold'>Indian Payment Method (Offline)</h3>
                        <p className='font-semibold'>PhonePe:</p>
                        <p className='font-semibold pb-6'>UPI: <span className='text-red-600'>8339991999@ibl</span></p>
                        <img alt="PhonePe UPI QR Code" src="https://toolsfix.net/UPI.jpg" className='w-1/2 sm:w-1/2 md:w-1/3' />
                    </div>
                </div>

                <p className='text-center'>Remember to share your <strong>username</strong> or mail id along with the <span ><strong>payment screenshot on our What&#39;s App</strong></span>.</p>

            </div>
        </section>
    )
}

export default Payment